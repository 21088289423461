import React from 'react';
import PropTypes from 'prop-types';

import AttributeStaticListComponent from '../../../../common/components/attribute-list/attribute-static-list-component';

import { ResourceWestwoodDataField } from '../../models/result';
import { MessageBoxGlobal } from '../../../../common/globals/globals';
import { StringLookupResult } from '../../../../common/models/string-lookup-result';
import { RECORD_STATUS } from '../../../../common/models/record-status';
import { PropertyGridStringValue, PropertyGridSelectValue } from '../../../../shared/components/property-grid/property-grid-component';
import { PropertyGridLargeStringValue } from '../../../../shared/components/property-grid/property-grid-table-component';

const IDS = {
  ID: 5,
  NAME: 10,
  PROJECT: 15,
  COUNTRY: 20,
  STATUS: 25,
  PRODUCT: 30,
  STRUCTURE: 35,
  TYPE: 40,
  DEVELOPMENT_SUMMARY: 45,
  BLOCK: 50,
};
Object.freeze(IDS);

const WestwoodDataDPFFieldComponent = (props) => {
  const { editing, messageBoxGlobal, lookupsWestwood, dpfFields, dpfFieldsActions, id } = props;

  const onEdit = (id, value, item) => {
    switch (id) {
      case IDS.ID: item.fieldId = value; break;

      default: throw new Error('Id ' + id + ' is not supported in WestwoodDataDPFFieldComponent.onEdit');
    }

    dpfFieldsActions.onEdit(item);
    return true;
  };

  const getTitle = (item) => {
    let value = item.field === undefined || item.field === null ? '' : item.field.fieldName;

    if (item.status === RECORD_STATUS.added && value === '') return '-New DPF Field--';

    return value;
  };

  const getRows = (item) => {
    let rows = [];
    rows.push({ label: 'Field', values: [new PropertyGridSelectValue(IDS.ID, item.fieldId, (value, id) => onEdit(id, value, item), 'key', 'value', lookupsWestwood.DPFField)] });
    if (item.field === undefined || item.field === null) return rows;

    let dpfField = item.field;

    rows.push({ label: 'Westwood Data Field Id', values: [new PropertyGridStringValue(IDS.ID, dpfField.id)] });
    //rows.push({ label: 'Field', values: [new PropertyGridStringValue(IDS.NAME, dpfField.fieldName)] });
    rows.push({ label: 'Project', values: [new PropertyGridStringValue(IDS.PROJECT, dpfField.projectName)] });
    rows.push({ label: 'Country', values: [new PropertyGridStringValue(IDS.COUNTRY, dpfField.country)] });
    rows.push({ label: 'Status', values: [new PropertyGridStringValue(IDS.STATUS, dpfField.status)] });
    rows.push({ label: 'Product', values: [new PropertyGridStringValue(IDS.PRODUCT, dpfField.fieldProduct)] });
    rows.push({ label: 'Development Structure', values: [new PropertyGridStringValue(IDS.STRUCTURE, dpfField.developmentStructure)] });
    rows.push({ label: 'Development Type', values: [new PropertyGridStringValue(IDS.TYPE, dpfField.developmentType)] });
    rows.push({ label: 'Development Summary', values: [new PropertyGridLargeStringValue(IDS.DEVELOPMENT_SUMMARY, dpfField.developmentSummary)] });
    rows.push({ label: 'Block', values: [new PropertyGridStringValue(IDS.BLOCK, dpfField.blockNumber)] });

    return rows;
  }

  const items = dpfFields.map(item => {
    return {
      label: getTitle(item),
      data: item,
      sections: [{
        label: 'DPF Field',
        rows: getRows(item)
      }]
    }
  });

  const add = () => {
    let newItem = new ResourceWestwoodDataField(null);
    newItem.getNew(id);

    if (dpfFields.length > 0) {
      dpfFields[0].status = dpfFields[0].status === RECORD_STATUS.added ? dpfFields[0].status : RECORD_STATUS.edited;
    }

    dpfFieldsActions.onAdd(newItem);
  }

  return (
    <AttributeStaticListComponent
      editing={editing}
      messageBoxGlobal={messageBoxGlobal}
      items={items}
      itemDescription="DPF Field"
      canAdd
      canDelete
      onAdd={add}
      onDelete={dpfFieldsActions.onDelete}
      onSelected={dpfFieldsActions.onSelected}
      onAddPosition={"start"}
    />
  );
};

WestwoodDataDPFFieldComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  lookupsWestwood: PropTypes.instanceOf(StringLookupResult).isRequired,
  dpfFields: PropTypes.arrayOf(PropTypes.instanceOf(ResourceWestwoodDataField)).isRequired,
  dpfFieldsActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired
};

export default WestwoodDataDPFFieldComponent;
