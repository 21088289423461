import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';
import GenericLocation from '../../../common/models/generic-location';
import { CircleModel } from '../../../shared/helpers/leaflet/models/circle-model';
import { MapShapeBorder } from '../../../shared/scss/app-colors';
import { LookupResult } from '../../../common/models/lookup-result';
import { ResourceWell } from '../../../common/models/resource-well';
import Ownership from '../../../common/models/ownership';
import { getDate } from '../../../shared/helpers/date-helper';
import { unitConversions } from '../../../shared/helpers/unit-conversions';
import { WestwoodDataWell } from '../../../common/models/westwood-data-well';

class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      // tbl_well attributes
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (typeof obj.well !== 'string') { throw new Error('Invalid obj.well param supplied to Attributes.ctor'); }
      if (typeof obj.blockId !== 'number') { throw new Error('Invalid obj.blockId param supplied to Attributes.ctor'); }
      if (typeof obj.blockNo !== 'string') { throw new Error('Invalid obj.blockNo param supplied to Attributes.ctor'); }
      if (typeof obj.wellProgrammeId !== 'number') { throw new Error('Invalid obj.wellProgrammeId param supplied to Attributes.ctor'); }
      if (typeof obj.currentClassId !== 'number') { throw new Error('Invalid obj.currentClassId param supplied to Attributes.ctor'); }
      if (typeof obj.subClassId !== 'number') { throw new Error('Invalid obj.subClassId param supplied to Attributes.ctor'); }
      if (typeof obj.intendedTrackId !== 'number') { throw new Error('Invalid obj.intendedTrackId param supplied to Attributes.ctor'); }
      if (typeof obj.currentStatusId !== 'number') { throw new Error('Invalid obj.currentStatusId param supplied to Attributes.ctor'); }
      if (typeof obj.primaryObjectiveId !== 'number') { throw new Error('Invalid obj.primaryObjectiveId param supplied to Attributes.ctor'); }
      if (typeof obj.secondaryObjectiveId !== 'number') { throw new Error('Invalid obj.secondaryObjectiveId  param supplied to Attributes.ctor'); }
      if (typeof obj.reservoirConditionId !== 'number') { throw new Error('Invalid obj.reservoirConditionId  param supplied to Attributes.ctor'); }
      if (typeof obj.bhfId !== 'number') { throw new Error('Invalid obj.bhfId  param supplied to Attributes.ctor'); }
      if (obj.licenceId !== null && typeof obj.licenceId !== 'number') { throw new Error('Invalid obj.licenceId param supplied to Attributes.ctor'); }
      if (obj.startDate !== null && typeof obj.startDate !== 'string') { throw new Error('Invalid obj.startDate param supplied to Attributes.ctor'); }
      if (obj.tdDate !== null && typeof obj.tdDate !== 'string') { throw new Error('Invalid obj.tdDate param supplied to Attributes.ctor'); }
      if (obj.endDate !== null && typeof obj.endDate !== 'string') { throw new Error('Invalid obj.endDate param supplied to Attributes.ctor'); }
      if (obj.tmdFt !== null && typeof obj.tmdFt !== 'number') { throw new Error('Invalid obj.tmdFt param supplied to Attributes.ctor'); }
      if (obj.wdFt !== null && typeof obj.wdFt !== 'number') { throw new Error('Invalid obj.wdFt param supplied to Attributes.ctor'); }
      if (obj.officialUID !== null && typeof obj.officialUID !== 'string') { throw new Error('Invalid obj.officialUID param supplied to Attributes.ctor'); }
      if (typeof obj.wellPurposeId !== 'number') { throw new Error('Invalid obj.wellPurposeId  param supplied to Attributes.ctor'); }
      if (obj.stratLevelId !== null && typeof obj.stratLevelId !== 'number') { throw new Error('Invalid obj.stratLevelId param supplied to Attributes.ctor'); }
      if (typeof obj.autoUpdate !== 'boolean') { throw new Error('Invalid obj.autoUpdate param supplied to Attributes.ctor'); }

      if (typeof obj.statusParent !== 'string') { throw new Error('Invalid obj.statusParent param supplied to Attributes.ctor'); }
      if (typeof obj.originalClassId !== 'number') { throw new Error('Invalid obj.originalClassId param supplied to Attributes.ctor'); }
      if (typeof obj.wellFunctionId !== 'number') { throw new Error('Invalid obj.functionId param supplied to Attributes.ctor'); }
      if (typeof obj.wellContentId !== 'number') { throw new Error('Invalid obj.contentId param supplied to Attributes.ctor'); }
      if (typeof obj.isJunked !== 'boolean') { throw new Error('Invalid obj.isJunked param supplied to Attributes.ctor'); }
      if (typeof obj.subsea !== 'boolean') { throw new Error('Invalid obj.subsea param supplied to Attributes.ctor'); }

      if (obj.originWellId !== null && typeof obj.originWellId !== 'number') { throw new Error('Invalid obj.originWellId param supplied to Attributes.ctor'); }
      if (obj.parentWellId !== null && typeof obj.parentWellId !== 'number') { throw new Error('Invalid obj.parentWellId param supplied to Attributes.ctor'); }
      if (obj.originWell !== null && typeof obj.originWell !== 'string') { throw new Error('Invalid obj.originWell param supplied to Attributes.ctor'); }
      if (obj.parentWell !== null && typeof obj.parentWell !== 'string') { throw new Error('Invalid obj.parentWell param supplied to Attributes.ctor'); }

      if (obj.kickOffLocationFt !== null && typeof obj.kickOffLocationFt !== 'number') { throw new Error('Invalid obj.kickOffLocationFt param supplied to Attributes.ctor'); }

      // tbl_well_admin attributes
      if (obj.prospectDiscoveryFieldName !== null && typeof obj.prospectDiscoveryFieldName !== 'string') { throw new Error('Invalid obj.prospectDiscoveryFieldName param supplied to Attributes.ctor'); }
      if (obj.governmentWellName !== null && typeof obj.governmentWellName !== 'string') { throw new Error('Invalid obj.governmentWellName param supplied to Attributes.ctor'); }
      if (obj.filemakerURN !== null && typeof obj.filemakerURN !== 'number') { throw new Error('Invalid obj.filemakerURN param supplied to Attributes.ctor'); }
      if (obj.dtiPon14Submitted !== null && typeof obj.dtiPon14Submitted !== 'string') { throw new Error('Invalid obj.dtiPon14Submitted param supplied to Attributes.ctor'); }
      if (obj.dtiPon14Approved !== null && typeof obj.dtiPon14Approved !== 'string') { throw new Error('Invalid obj.dtiPon14Approved param supplied to Attributes.ctor'); }
      if (obj.dtiPon15Submitted !== null && typeof obj.dtiPon15Submitted !== 'string') { throw new Error('Invalid obj.dtiPon15Submitted param supplied to Attributes.ctor'); }
      if (obj.dtiPon15Approved !== null && typeof obj.dtiPon15Approved !== 'string') { throw new Error('Invalid obj.dtiPon15Approved param supplied to Attributes.ctor'); }
      if (obj.preSpudComment !== null && typeof obj.preSpudComment !== 'string') { throw new Error('Invalid obj.preSpudComment param supplied to Attributes.ctor'); }
      if (obj.earliestTimingStart !== null && typeof obj.earliestTimingStart !== 'string') { throw new Error('Invalid obj.earliestTimingStart param supplied to Attributes.ctor'); }
      if (obj.latestTimingStart !== null && typeof obj.latestTimingStart !== 'string') { throw new Error('Invalid obj.latestTimingStart param supplied to Attributes.ctor'); }
      if (obj.timing !== null && typeof obj.timing !== 'string') { throw new Error('Invalid obj.timing param supplied to Attributes.ctor'); }
      if (obj.dateComment !== null && typeof obj.dateComment !== 'string') { throw new Error('Invalid obj.dateComment param supplied to Attributes.ctor'); }
      if (obj.depthComment !== null && typeof obj.depthComment !== 'string') { throw new Error('Invalid obj.depthComment param supplied to Attributes.ctor'); }
      if (obj.ptdFt !== null && typeof obj.ptdFt !== 'number') { throw new Error('Invalid obj.ptdFt param supplied to Attributes.ctor'); }
      if (obj.tvdssFt !== null && typeof obj.tvdssFt !== 'number') { throw new Error('Invalid obj.tvdssFt param supplied to Attributes.ctor'); }
      if (typeof obj.opportunity !== 'boolean') { throw new Error('Invalid obj.opportunity param supplied to Attributes.ctor'); }
      if (obj.commitmentId !== null && typeof obj.commitmentId !== 'number') { throw new Error('Invalid obj.commitmentId param supplied to Attributes.ctor'); }
      if (obj.locationProvenaceId !== null && typeof obj.locationProvenaceId !== 'number') { throw new Error('Invalid obj.locationProvenaceId param supplied to Attributes.ctor'); }
      if (obj.dealId !== null && typeof obj.dealId !== 'number') { throw new Error('Invalid obj.dealId param supplied to Attributes.ctor'); }

      // tbl_well_rig attributes
      if (obj.rigId !== null && typeof obj.rigId !== 'number') { throw new Error('Invalid obj.rigId param supplied to Attributes.ctor'); }
      if (obj.elevationTypeId !== null && typeof obj.elevationTypeId !== 'number') { throw new Error('Invalid obj.elevationTypeId param supplied to Attributes.ctor'); }
      if (obj.costSourceId !== null && typeof obj.costSourceId !== 'number') { throw new Error('Invalid obj.costSourceId param supplied to Attributes.ctor'); }
      if (obj.elevationFt !== null && typeof obj.elevationFt !== 'number') { throw new Error('Invalid obj.elevationFt param supplied to Attributes.ctor'); }
      if (obj.rigRateDpd !== null && typeof obj.rigRateDpd !== 'number') { throw new Error('Invalid obj.rigRateDpd param supplied to Attributes.ctor'); }
      if (obj.rigUplift !== null && typeof obj.rigUplift !== 'number') { throw new Error('Invalid obj.rigUplift param supplied to Attributes.ctor'); }
      if (obj.manualWellCostUSDMM !== null && typeof obj.manualWellCostUSDMM !== 'number') { throw new Error('Invalid obj.manualWellCostUSDMM param supplied to Attributes.ctor'); }
      if (obj.costSourceDescription !== null && typeof obj.costSourceDescription !== 'string') { throw new Error('Invalid obj.costSourceDescription param supplied to Attributes.ctor'); }

      // calculated attributes
      if (obj.daysToTD !== null && typeof obj.daysToTD !== 'number') { throw new Error('Invalid obj.daysToTD param supplied to Attributes.ctor'); }
      if (obj.daysFromTD !== null && typeof obj.daysFromTD !== 'number') { throw new Error('Invalid obj.daysFromTD param supplied to Attributes.ctor'); }
      if (obj.fullDuration !== null && typeof obj.fullDuration !== 'number') { throw new Error('Invalid obj.fullDuration param supplied to Attributes.ctor'); }
      if (obj.tdDuration !== null && typeof obj.tdDuration !== 'number') { throw new Error('Invalid obj.tdDuration param supplied to Attributes.ctor'); }
      if (obj.calculatedWellCostUSDMM !== null && typeof obj.calculatedWellCostUSDMM !== 'number') { throw new Error('Invalid obj.calculatedWellCostUSDMM param supplied to Attributes.ctor'); }
      //if (typeof obj.isFuture !== 'boolean') { throw new Error('Invalid obj.isFuture param supplied to Attributes.ctor'); }
      //if (typeof obj.isFutureOrActive !== 'boolean') { throw new Error('Invalid obj.isFutureOrActive param supplied to Attributes.ctor'); }
      if (typeof obj.isPlanned !== 'boolean') { throw new Error('Invalid obj.isPlanned param supplied to Attributes.ctor'); }
      if (typeof obj.isPlannedOrActive !== 'boolean') { throw new Error('Invalid obj.isPlannedOrActive param supplied to Attributes.ctor'); }
      
      // collections
      if (!Array.isArray(obj.comments)) { throw new Error('Invalid obj.comments param supplied to Attributes.ctor'); }
      if (!Array.isArray(obj.locations)) { throw new Error('Invalid obj.locations param supplied to Attributes.ctor'); }
      if (obj.ownership !== null && !Array.isArray(obj.ownership)) { throw new Error('Invalid obj.ownership param supplied to Attributes.ctor'); }
      if (obj.resourceWell !== null && !Array.isArray(obj.resourceWell)) { throw new Error('Invalid obj.resourceWell param supplied to Attributes.ctor'); }

      if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to Attributes.ctor'); }
    }

    // tbl_well attributes
    this.id = obj === null ? 0 : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.well = obj === null ? '' : obj.well;
    this.blockId = obj === null ? null : obj.blockId;
    this.blockNo = obj === null ? '' : obj.blockNo;
    this.wellProgrammeId = obj === null ? null : obj.wellProgrammeId;
    this.currentClassId = obj === null ? null : obj.currentClassId;
    this.subClassId = obj === null ? null : obj.subClassId;
    this.intendedTrackId = obj === null ? null : obj.intendedTrackId;
    this.currentStatusId = obj === null ? null : obj.currentStatusId;
    this.primaryObjectiveId = obj === null ? null : obj.primaryObjectiveId;
    this.secondaryObjectiveId = obj === null ? null : obj.secondaryObjectiveId;
    this.reservoirConditionId = obj === null ? null : obj.reservoirConditionId;
    this.bhfId = obj === null ? null : obj.bhfId;
    this.licenceId = obj === null || obj.licenceId === null ? null : obj.licenceId;
    this.startDate = obj === null ? null : getDate(obj.startDate);
    this.tdDate = obj === null ? null : getDate(obj.tdDate);
    this.endDate = obj === null ? null : getDate(obj.endDate);
    this.tmdFt = obj === null || obj.tmdFt === null ? null : obj.tmdFt;
    this.tmdM = obj === null || obj.tmdFt === null || obj.tmdFt === 0 ? null : unitConversions.Convert(unitConversions.feetToMeters, obj.tmdFt);
    this.wdFt = obj === null || obj.wdFt === null ? null : obj.wdFt;
    this.wdM = obj === null || obj.wdFt === null || obj.wdFt === 0 ? null : unitConversions.Convert(unitConversions.feetToMeters, obj.wdFt);
    this.officialUID = obj === null ? null : obj.officialUID;
    this.wellPurposeId = obj === null ? null : obj.wellPurposeId;
    this.stratLevelId = obj === null || obj.stratLevelId === null ? null : obj.stratLevelId;
    this.autoUpdate = obj === null ? false : obj.autoUpdate;

    this.statusParent = obj === null ? null : obj.statusParent;
    this.originalClassId = obj === null ? null : obj.originalClassId;
    this.wellFunctionId = obj === null ? null : obj.wellFunctionId;
    this.wellContentId = obj === null ? null : obj.wellContentId;
    this.isJunked = obj === null ? null : obj.isJunked;
    this.subsea = obj === null ? null : obj.subsea;

    this.originWellId = obj === null ? null : obj.originWellId;
    this.parentWellId = obj === null ? null : obj.parentWellId;
    this.originWell = obj.originWell;
    this.parentWell = obj.parentWell;

    this.kickOffLocationFt = obj === null || obj.kickOffLocationFt === null ? null : obj.kickOffLocationFt;
    this.kickOffLocationM = obj === null || obj.kickOffLocationFt === null || obj.kickOffLocationFt === 0 ? null : unitConversions.Convert(unitConversions.feetToMeters, obj.kickOffLocationFt);
    
    // tbl_well_admin attributes
    this.prospectDiscoveryFieldName = obj === null ? null : obj.prospectDiscoveryFieldName;
    this.governmentWellName = obj === null ? null : obj.governmentWellName;
    this.filemakerURN = obj === null || obj.filemakerURN === null ? null : obj.filemakerURN;
    this.dtiPon14Submitted = obj === null ? null : getDate(obj.dtiPon14Submitted);
    this.dtiPon14Approved = obj === null ? null : getDate(obj.dtiPon14Approved);
    this.dtiPon15Submitted = obj === null ? null : getDate(obj.dtiPon15Submitted);
    this.dtiPon15Approved = obj === null ? null : getDate(obj.dtiPon15Approved);
    this.preSpudComment = obj === null ? null : obj.preSpudComment;
    this.earliestTimingStart = obj === null ? null : getDate(obj.earliestTimingStart);
    this.latestTimingStart = obj === null ? null : getDate(obj.latestTimingStart);
    this.timing = obj === null ? null : obj.timing;
    this.dateComment = obj === null ? null : obj.dateComment;
    this.depthComment = obj === null ? null : obj.depthComment;
    this.ptdFt = obj === null || obj.ptdFt === null ? null : obj.ptdFt;
    this.ptdM = obj === null || obj.ptdFt === null || obj.ptdFt === 0 ? null : unitConversions.Convert(unitConversions.feetToMeters, obj.ptdFt);
    this.tvdssFt = obj === null || obj.tvdssFt === null ? null : obj.tvdssFt;
    this.tvdssM = obj === null || obj.tvdssFt === null || obj.tvdssFt === 0 ? null : unitConversions.Convert(unitConversions.feetToMeters, obj.tvdssFt);
    this.opportunity = obj === null ? false : obj.opportunity;
    this.commitmentId = obj === null || obj.commitmentId === null ? null : obj.commitmentId;
    this.locationProvenaceId = obj === null || obj.locationProvenaceId === null ? null : obj.locationProvenaceId;
    this.dealId = obj === null || obj.dealId === null ? null : obj.dealId;

    // tbl_well_rig attributes
    this.rigId = obj === null || obj.rigId === null ? null : obj.rigId;
    this.elevationTypeId = obj === null || obj.elevationTypeId === null ? null : obj.elevationTypeId;
    this.costSourceId = obj === null || obj.costSourceId === null ? null : obj.costSourceId;
    this.elevationFt = obj === null || obj.elevationFt === null ? null : obj.elevationFt;
    this.elevationM = obj === null || obj.elevationFt === null || obj.elevationFt === 0 ? null : unitConversions.Convert(unitConversions.feetToMeters, obj.elevationFt);
    this.rigRateDpd = obj === null || obj.rigRateDpd === null ? null : obj.rigRateDpd;
    this.rigUplift = obj === null || obj.rigUplift === null ? null : obj.rigUplift;
    this.manualWellCostUSDMM = obj === null || obj.manualWellCostUSDMM === null ? null : obj.manualWellCostUSDMM;
    this.costSourceDescription = obj === null || obj.costSourceDescription === null ? null : obj.costSourceDescription;

    // calculated attributes
    this.daysToTD = obj === null || obj.daysToTD === null ? null : obj.daysToTD;
    this.daysFromTD = obj === null || obj.daysFromTD === null ? null : obj.daysFromTD;
    this.fullDuration = obj === null || obj.fullDuration === null ? null : obj.fullDuration;
    this.tdDuration = obj === null || obj.tdDuration === null ? null : obj.tdDuration;
    this.calculatedWellCostUSDMM = obj === null || obj.calculatedWellCostUSDMM === null ? null : obj.calculatedWellCostUSDMM;
    this.isFuture = obj === null ? false : obj.isPlanned;
    this.isFutureOrActive = obj === null ? false : obj.isPlannedOrActive;
    
    // collections
    this.comments = (obj === null || obj.comments === null) ? [new GMatrixGenericComment(null)] : obj.comments.map(item => new GMatrixGenericComment(item));
    this.locations = (obj === null || obj.locations === null) ? [new GenericLocation(null)] : obj.locations.map(item => new GenericLocation(item));
    this.points = obj === null || obj.locations === null ? null : obj.locations.map(p => new CircleModel(p.point.y, p.point.x, { borderColor: p.bottomHole === true ? '#fd40a5' : MapShapeBorder, fillColor: 'transparent', radius: 700 }));
    this.ownership = (obj === null || obj.ownership === null) ? [new Ownership(null)] : obj.ownership.map(item => new Ownership(item));
    this.resourceWell = (obj === null || obj.resourceWell === null) ? [new ResourceWell(null)] : obj.resourceWell.map(item => new ResourceWell(item));

    this.countryId = obj === null ? 0 : obj.countryId;

    this.status = obj === null ? '' : obj.status;
    this.originalStatus = obj === null ? '' : obj.originalStatus;
  }
}

export class Result {
  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes() : new Attributes(obj.attributes);
    this.westwoodDataWell = obj === null || obj === undefined ? new WestwoodDataWell(null) : new WestwoodDataWell(obj.westwoodDataWell);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
    this.lookupsFiltered = obj === null || obj === undefined || obj.lookupsFiltered === null || obj.lookupsFiltered === undefined ? new LookupResult() : new LookupResult(obj.lookupsFiltered);
  }
}

export { Issue, EditLock, Attributes, Ownership, ResourceWell, unitConversions };
