import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import * as ViewFunctions from '../view-common/view-functions';
import './well-view.scss';

// Entity Models
import { Result } from './models/result';
import { PostbackInfo } from '../view-common/postback-info';
import { NewWellDataResult } from './models/new-well-data-result';

// Entity Components
import TopPanelComponent from './components/top-panel/top-panel-component';
import BottomPanelComponent from './components/bottom-panel/bottom-panel-component';
import NewWellComponent from './components/popup-panels/new-well-component';

// Common models
import { RECORD_STATUS } from './../../common/models/record-status';
import { Views, ViewGlobal, IdGlobal, LoadingGlobal, MessageBoxGlobal, CountriesGlobal, EditingGlobal, SearchGlobal, PinLocationGlobal } from '../../common/globals/globals';

// Common components
import EditableViewComponent, { layerType } from '../../common/components/editable-view/editable-view-component';
import { Button } from 'reactstrap';

// Shared components
import SplitterPanelComponent from '../../shared/components/splitter-panel/splitter-panel-component';

// Helpers
import { apiPost } from '../../common/helpers/api-helpers';
import { RELATED_ITEM_TYPE } from '../../common/models/related-item-type';
import { GMATRIX_ENTITY } from '../../common/models/gmatrix-entity';

class WellView extends React.Component {
  constructor(props) {
    super(props);

    this.beginEdit = ViewFunctions.beginEdit.bind(this);
    this.cancelEdit = ViewFunctions.cancelEdit.bind(this);
    this.canButtonsEnable = ViewFunctions.canButtonsEnable.bind(this);
    this.canPerformAction = ViewFunctions.canPerformAction.bind(this);
    this.copyEntity = ViewFunctions.copyEntity.bind(this);
    this.copyPinLocationToLocationObject = ViewFunctions.copyPinLocationToLocationObject.bind(this);
    this.deleteEntity = ViewFunctions.deleteEntity.bind(this);
    this.didMount = ViewFunctions.didMount.bind(this);
    this.getActions = ViewFunctions.getActions.bind(this);
    this.getCurrentEntity = ViewFunctions.getCurrentEntity.bind(this);
    this.getData = ViewFunctions.getData.bind(this);
    this.handleCopyEntity = ViewFunctions.handleCopyEntity.bind(this);
    this.initData = ViewFunctions.initData.bind(this);
    this.populatePostbackInfo = ViewFunctions.populatePostbackInfo.bind(this);
    this.refresh = ViewFunctions.refresh.bind(this);
    this.resetEditTimeout = ViewFunctions.resetEditTimeout.bind(this);
    this.saveEdit = ViewFunctions.saveEdit.bind(this);
    this.setDataInState = ViewFunctions.setDataInState.bind(this)
    this.setSelected = ViewFunctions.setSelected.bind(this);
    this.updateAttributes = ViewFunctions.updateAttributes.bind(this);
    this.updateData = ViewFunctions.updateData.bind(this)
    this.updateSearchResults = ViewFunctions.updateSearchResults.bind(this);

    // OVERRIDE COMMON FUNCTION METHODS
    this.newData = this.newData.bind(this);

    this.lookups = null;
    this.state = { entityData: null, contextLayers: [], canEnable: true, selectedItems: null };
  }

  get apiPrefix() { return 'api/well/' };
  get className() { return 'view-panel well-view' };
  get currentView() { return Views.Well };
  get currentViewName() { return Views.displayName(this.currentView) };
  get mapComponentName() { return 'well-view-component-map' };
  get contextLayerType() { return layerType.EAndAWells };
  get gmatrixEntity() { return GMATRIX_ENTITY.Well; }
  get ownershipLookupURL() { return this.apiPrefix + 'lookups/ownership' };

  get viewGlobal() { return this.props.viewGlobal; };
  get idGlobal() { return this.props.idGlobal; };
  get editingGlobal() { return this.props.editingGlobal; };
  get countriesGlobal() { return this.props.countriesGlobal; };
  get loadingGlobal() { return this.props.loadingGlobal; };
  get messageBoxGlobal() { return this.props.messageBoxGlobal; };
  get searchGlobal() { return this.props.searchGlobal; };
  get pinLocationGlobal() { return this.props.pinLocationGlobal; };
  get copyRecordId() { return this.props.copyRecordId; };

  componentDidMount() {
    this.didMount();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.idGlobal.value !== this.props.idGlobal.value) {
      this.initData();
    }
  }

  getPostbackInfo(forEdit, autoClose) {
    const result = new PostbackInfo();
    this.populatePostbackInfo(result, forEdit, autoClose);

    if (result !== null && result.payload !== null && result.payload.westwoodDataWell !== null) {
      result.payload.westwoodDataWell = null;
    }

    return result;
  }

  setData(newData, callback = undefined) {
    let entity = newData === null ? null : new Result(newData);

    if (entity !== null && !(entity instanceof Result)) {
      throw new Error('Invalid newData param supplied to setData');
    }

    this.setDataInState(entity, callback);
  }

  /************* START - COMMON VIEW FUNCTION OVERRIDES *************/

  newData() {
    const countryIds = this.countriesGlobal.value;
    if (countryIds === null || countryIds === undefined || countryIds.length === 0) {
      this.messageBoxGlobal.showError('New Well. Countries not found!');
      this.newWellCancelFail(false);
      return;
    }

    const onSuccess = (result) => {
      const newWellData = new NewWellDataResult(result);
      let msgContents = <NewWellComponent newWellData={newWellData} onClose={this.handleNewWell} />;
      this.messageBoxGlobal.showPanel(msgContents, 'New Well', 'lg');
    };
    apiPost(this.apiPrefix + 'new-well-data', JSON.stringify(countryIds), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, null);
  }

  /************* END - COMMON VIEW FUNCTION OVERRIDES *************/

  /************* START VIEW SPECIFIC METHODS *************/

  copyOwnership = () => {
    const postbackInfo = this.getPostbackInfo(true, false);

    const onSuccess = (result) => this.setData(result);
    apiPost(this.apiPrefix + 'copy-ownership', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, null);
  }

  copyPinLocation = () => {
    let entity = this.getCurrentEntity();

    if (entity !== null && entity.attributes !== null && entity.attributes.locations !== null) {
      const topHole = entity.attributes.locations.find(x => x.bottomHole === false);
      if (topHole !== null && topHole !== undefined) {
        this.copyPinLocationToLocationObject(topHole);
        this.updateData();
      }
    }
  }

  handleNewWell = (cancel, wellProgrammeId, country, isUnassigned) => {
    if (cancel === true) {
      this.newWellCancelFail();
      return;
    }

    let programmeId = typeof wellProgrammeId === 'number' ? wellProgrammeId : null;
    let countryId = parseInt(country);
    if (typeof countryId !== 'number') countryId = -1;

    if ((programmeId === undefined || programmeId === null || programmeId === '') && !isUnassigned) {
      this.newWellCancelFail();
    }
    if (countryId === undefined || countryId === null) {
      this.newWellCancelFail();
    }
    const postbackInfo = this.getPostbackInfo(true, false);

    postbackInfo.newWellProgrammeId = wellProgrammeId;
    postbackInfo.newWellCountryId = countryId;
    postbackInfo.newWellIsUnassignedWellProgramme = isUnassigned;

    const onSuccess = (result) => this.setData(result, () => {
      this.messageBoxGlobal.clear(this.idGlobal.set(result.attributes.id, result.attributes.well, this.searchGlobal.search(result.attributes.well)));
      this.editingGlobal.set(true);

    });
    //const onFail = (e) => this.idGlobal.clear(() => callApiDefaultOnFail(e, this.messageBoxGlobal));
    apiPost(this.apiPrefix + 'new', JSON.stringify(postbackInfo), this.loadingGlobal.show, this.loadingGlobal.hide, this.messageBoxGlobal, onSuccess, null);
  }

  newWellCancelFail = (clear = true) => {
    if (clear === true) this.messageBoxGlobal.clear();
    this.idGlobal.clear();
    this.updateSearchResults();
    this.setState({ entityData: null });
  }

  ownershipDisabled = () => {
    if (!this.editingGlobal.value) return true;
    const entity = this.getCurrentEntity();
    if (entity === null || entity.attributes === null || entity.attributes.ownership === null) return true;

    if (entity.attributes.isFuture) return true;
    if (entity.attributes.blockId < 1) return true;
    if (entity.attributes.ownership.length === 0) return false;

    return true;

  }

  getResourceWellActions = (type) => {
    const entity = this.getCurrentEntity();
    return this.getActions(entity.attributes.resourceWell, type)
  }

  /************* END VIEW SPECIFIC METHODS *************/

  render() {
    const entity = this.getCurrentEntity();
    const { canEnable } = this.state;

    if (entity === null || this.lookups === null) {
      return <div className="view-panel no-data"><h1>Please select a {this.currentViewName}, or click<span className="new-button" onClick={() => this.idGlobal.setNewRecordId()} />for a new {this.currentViewName}.</h1></div>;
    }

    const { attributes, editLock, issues, lookupsFiltered, westwoodDataWell } = entity;
    const { id } = entity.attributes;

    const toolbarItems = [
      <Button key={0} className="btn-image first-button copy-well-button" size="sm" disabled={this.editingGlobal.value} onClick={this.handleCopyEntity}>Copy Well</Button>,
      <Button key={1} className="btn-image first-button copy-ownership-button" size="sm" disabled={this.ownershipDisabled()} onClick={this.copyOwnership}>Copy Block Ownership</Button>,
    ];

    const comments = attributes.comments.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const locations = attributes.locations.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const ownership = attributes.ownership.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const resourceWell = attributes.resourceWell.filter(obj => obj.status !== RECORD_STATUS.deleted);
    const editTimeout = this.editingGlobal.value && editLock !== undefined && editLock !== null ? moment(editLock.expiryTime).toDate() : undefined;
    const ownershipLookupURL = this.ownershipLookupURL + '?showAllCorporates=' + (attributes.isFutureOrActive ? false : true);

    return (
      <EditableViewComponent
        className={this.className}
        loadingGlobal={this.loadingGlobal}
        header={this.currentViewName + ': ' + attributes.well}
        messageBoxGlobal={this.messageBoxGlobal}
        editingGlobal={this.editingGlobal}
        issues={issues}
        canRefresh={!(this.idGlobal.isNewRecordId | this.idGlobal.isCopyRecordId)}
        canEnable={canEnable}
        refresh={this.refresh}
        beginEdit={this.beginEdit}
        cancelEdit={this.cancelEdit}
        saveEdit={this.saveEdit}
        editTimeout={editTimeout}
        resetEditTimeout={this.resetEditTimeout}
        toolbarItems={toolbarItems}
        toolbarItemsPosition="after"
        startYear={0}
        endYear={0}
        selectedStartYear={0}
        selectedEndYear={0}
        yearStartChange={() => { }}
        yearEndChange={() => { }}
        isProduction={false}
        canDelete={true}
        onDelete={this.deleteEntity}
        canCopyPin={true}
        pinLocationGlobal={this.pinLocationGlobal}
        onCopyPin={this.copyPinLocation}
        viewGlobal={this.props.viewGlobal}
        searchGlobal={this.props.searchGlobal}
        currentView={this.gmatrixEntity}
        currentId={this.idGlobal.value}
        showCalc={false}
        recordLocked={false}
        updateSnapshotAudit={() => { }}
        edited={false}
        calculate={() => { }}
        shortcuts={[]}
      >
        <SplitterPanelComponent vertical={false} percentage secondarySize={40}>
          <TopPanelComponent
            editing={this.editingGlobal.value}
            loadingGlobal={this.loadingGlobal}
            messageBoxGlobal={this.messageBoxGlobal}
            pinLocationGlobal={this.pinLocationGlobal}
            lookups={this.lookups}
            attributes={attributes}
            updateAttributes={this.updateAttributes}
            geoserverUrl={this.props.geoserverUrl}
            mapComponentName={this.mapComponentName}
            contextLayerType={this.contextLayerType}
            lookupsFiltered={lookupsFiltered}
          />
          <BottomPanelComponent
            editing={this.editingGlobal.value}
            loadingGlobal={this.loadingGlobal}
            messageBoxGlobal={this.messageBoxGlobal}
            countryId={attributes.countryId}
            isPlanned={attributes.isPlanned}
            comments={comments}
            commentActions={this.getActions(attributes.comments, RELATED_ITEM_TYPE.COMMENT)}
            locations={locations}
            locationActions={this.getActions(attributes.locations, RELATED_ITEM_TYPE.LOCATION)}
            ownership={ownership}
            ownershipActions={this.getActions(attributes.ownership, RELATED_ITEM_TYPE.OWNERSHIP)}
            ownershipLookupURL={ownershipLookupURL}
            resourceWell={resourceWell}
            //resourceWellActions={this.getActions(attributes.resourceWell, RELATED_ITEM_TYPE.WELL)}
            resourceWellActions={this.getResourceWellActions}
            lookupsFiltered={lookupsFiltered}
            entityId={id}
            westwoodDataWell={westwoodDataWell}
          />
        </SplitterPanelComponent>
      </EditableViewComponent>
    );
  }
};

WellView.propTypes = {
  viewGlobal: PropTypes.instanceOf(ViewGlobal).isRequired,
  idGlobal: PropTypes.instanceOf(IdGlobal).isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  countriesGlobal: PropTypes.instanceOf(CountriesGlobal).isRequired,
  searchGlobal: PropTypes.instanceOf(SearchGlobal).isRequired,
  editingGlobal: PropTypes.instanceOf(EditingGlobal).isRequired,
  pinLocationGlobal: PropTypes.instanceOf(PinLocationGlobal).isRequired,
  copyRecordId: PropTypes.number.isRequired
}

export default WellView;
