import { ResourceText } from '../../../common/models/resource-text';
import { ResourceAnnual } from '../../../common/models/resource-annual';
import { Issue } from '../../../common/models/issue';
import { EditLock } from '../../../common/models/edit-lock';
import { PolygonsModel, PolygonModel } from '../../../shared/helpers/leaflet/models/polygons-model';
import { MapShapeBorder } from '../../../shared/scss/app-colors';
import { CircleModel } from '../../../shared/helpers/leaflet/models/circle-model';
import { GMatrixGenericComment } from '../../../common/models/generic-comment';
import Ownership from '../../../common/models/ownership';
import GenericLocation from '../../../common/models/generic-location';
import { ResourceWell } from '../../../common/models/resource-well';
import { RECORD_STATUS } from '../../../common/models/record-status';
import { getDate } from '../../../shared/helpers/date-helper';
import { unitConversions } from '../../../shared/helpers/unit-conversions';
import { WestwoodDataDPFField } from '../../../common/models/westwood-data-dpf-field';

export class ResourceAlias {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to ResourceAlias.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to ResourceAlias.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.entityId param supplied to ResourceAlias.ctor'); }
      if (typeof obj.statusId !== 'number') { throw new Error('Invalid obj.statusId param supplied to ResourceAlias.ctor'); }
      if (typeof obj.alias !== 'string') { throw new Error('Invalid obj.alias param supplied to ResourceAlias.ctor'); }
      if (typeof obj.aliasStatus !== 'string') { throw new Error('Invalid obj.aliasStatus param supplied to ResourceAlias.ctor'); }
      if (obj.startDate !== null && typeof obj.startDate !== 'string') { throw new Error('Invalid obj.startDate param supplied to ResourceAlias.ctor'); }
      if (obj.endDate !== null && typeof obj.endDate !== 'string') { throw new Error('Invalid obj.endDate param supplied to ResourceAlias.ctor'); }

      if (typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to ResourceAlias.ctor'); }
      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to ResourceAlias.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to ResourceAlias.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to ResourceAlias.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.entityId = obj === null ? 0 : obj.entityId;
    this.statusId = obj === null ? 0 : obj.statusId;
    this.alias = obj === null ? '' : obj.alias;
    this.aliasStatus = obj === null ? '' : obj.aliasStatus;

    this.startDate = obj === null ? null : getDate(obj.startDate);
    this.endDate = obj === null ? null : getDate(obj.endDate);

    this.selected = obj === null ? false : obj.selected;
    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;
    this.status = obj === null ? '' : obj.status;
  }

  getNew(entityId) {
    this.entityId = entityId;
    this.endDate = null;
    this.selected = true;
    this.status = RECORD_STATUS.added;
  }
}

export class ResourceBlock {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to ResourceBlock.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to ResourceBlock.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.entityId param supplied to ResourceBlock.ctor'); }
      if (typeof obj.blockId !== 'number') { throw new Error('Invalid obj.blockId param supplied to ResourceBlock.ctor'); }
      if (typeof obj.blockName !== 'string') { throw new Error('Invalid obj.blockName param supplied to ResourceBlock.ctor'); }

      if (typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to ResourceBlock.ctor'); }
      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to ResourceBlock.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to ResourceBlock.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to ResourceBlock.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.entityId = obj === null ? 0 : obj.entityId;
    this.blockId = obj === null ? 0 : obj.blockId;
    this.blockName = obj === null ? '' : obj.blockName;

    this.selected = obj === null ? false : obj.selected;
    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;
    this.status = obj === null ? '' : obj.status;
  }

  getNew(entityId) {
    this.entityId = entityId;
    this.selected = true;
    this.status = RECORD_STATUS.added;
  }
}

export class OfficialUID {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to OfficialUID.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to OfficialUID.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.entityId param supplied to OfficialUID.ctor'); }
      if (typeof obj.officialUID !== 'string') { throw new Error('Invalid obj.officialUID param supplied to OfficialUID.ctor'); }
      if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to OfficialUID.ctor'); }
      if (typeof obj.name !== 'string') { throw new Error('Invalid obj.name param supplied to OfficialUID.ctor'); }

      if (typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to OfficialUID.ctor'); }
      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to OfficialUID.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to OfficialUID.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to OfficialUID.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.entityId = obj === null ? 0 : obj.entityId;
    this.officialUID = obj === null ? '' : obj.officialUID;
    this.countryId = obj === null ? 0 : obj.countryId;
    this.name = obj === null ? '' : obj.name;

    this.selected = obj === null ? false : obj.selected;
    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;
    this.status = obj === null ? '' : obj.status;

  }

  getNew(entityId) {
    this.entityId = entityId;
    this.selected = true;
    this.status = RECORD_STATUS.added;
  }
}

export class ResourceProcessing {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to ResourceProcessing.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to ResourceProcessing.ctor'); }
      if (typeof obj.entityId !== 'number') { throw new Error('Invalid obj.entityId param supplied to ResourceProcessing.ctor'); }
      if (typeof obj.hubId !== 'number') { throw new Error('Invalid obj.hubId param supplied to ResourceProcessing.ctor'); }
      if (typeof obj.hubDecisionId !== 'number') { throw new Error('Invalid obj.hubDecisionId param supplied to ResourceProcessing.ctor'); }
      if (typeof obj.hubName !== 'string') { throw new Error('Invalid obj.hubName param supplied to ResourceProcessing.ctor'); }
      if (typeof obj.hubDecision !== 'string') { throw new Error('Invalid obj.hubDecision param supplied to ResourceProcessing.ctor'); }

      if (typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to ResourceProcessing.ctor'); }
      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to ResourceProcessing.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to ResourceProcessing.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to ResourceProcessing.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.entityId = obj === null ? 0 : obj.entityId;
    this.hubId = obj === null ? 0 : obj.hubId;
    this.hubDecisionId = obj === null ? 0 : obj.hubDecisionId;
    this.hubName = obj === null ? '' : obj.hubName;
    this.hubDecision = obj === null ? '' : obj.hubDecision;

    this.selected = obj === null ? false : obj.selected;
    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;
    this.status = obj === null ? '' : obj.status;
  }

  getNew(entityId) {
    this.entityId = entityId;
    this.hubDecisionId = 1;
    this.hubDecision = 'Already Established';
    this.selected = true;
    this.status = RECORD_STATUS.added;
  }
}

export class Attributes {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to Attributes.ctor'); }
    if (obj !== null) {
      if (obj.id !== null && typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to Attributes.ctor'); }
      if (typeof obj.displayName !== 'string') { throw new Error('Invalid obj.displayName param supplied to Attributes.ctor'); }
      if (obj.resourceName !== null && typeof obj.resourceName !== 'string') { throw new Error('Invalid obj.resourceName param supplied to Attributes.ctor'); }
      if (obj.newResourceName !== null && typeof obj.newResourceName !== 'string') { throw new Error('Invalid obj.newResourceName param supplied to Attributes.ctor'); }
      if (obj.originalResourceName !== null && typeof obj.originalResourceName !== 'string') { throw new Error('Invalid obj.originalResourceName param supplied to Attributes.ctor'); }
      if (typeof obj.blockId !== 'number') { throw new Error('Invalid obj.blockId param supplied to Attributes.ctor'); }
      if (typeof obj.blockNo !== 'string') { throw new Error('Invalid obj.blockNo param supplied to Attributes.ctor'); }
      if (typeof obj.statusId !== 'number') { throw new Error('Invalid obj.statusId param supplied to Attributes.ctor'); }
      if (typeof obj.typeId !== 'number') { throw new Error('Invalid obj.typeId param supplied to Attributes.ctor'); }
      if (typeof obj.conditionId !== 'number') { throw new Error('Invalid obj.conditionId param supplied to Attributes.ctor'); }
      if (typeof obj.productionSchemeId !== 'number') { throw new Error('Invalid obj.productionSchemeId param supplied to Attributes.ctor'); }
      if (typeof obj.productivityGroupId !== 'number') { throw new Error('Invalid obj.productivityGroupId param supplied to Attributes.ctor'); }
      if (typeof obj.developmentConceptId !== 'number') { throw new Error('Invalid obj.developmentConceptId param supplied to Attributes.ctor'); }
      if (typeof obj.classificationId !== 'number') { throw new Error('Invalid obj.classificationId param supplied to Attributes.ctor'); }
      if (typeof obj.waterDepthFt !== 'number') { throw new Error('Invalid obj.waterDepthFt param supplied to Attributes.ctor'); }
      if (typeof obj.activityAudited !== 'boolean') { throw new Error('Invalid obj.activityAudited param supplied to Attributes.ctor'); }
      if (typeof obj.initialCombinedMmboe !== 'number') { throw new Error('Invalid obj.initialCombinedMmboe param supplied to Attributes.ctor'); }
      if (typeof obj.remainingCombinedMmboe !== 'number') { throw new Error('Invalid obj.remainingCombinedMmboe param supplied to Attributes.ctor'); }
      if (typeof obj.hasWGEShape !== 'boolean') { throw new Error('Invalid obj.hasWGEShape param supplied to Attributes.ctor'); }
      if (obj.stratLevelId !== null && typeof obj.stratLevelId !== 'number') { throw new Error('Invalid obj.stratLevelId param supplied to Attributes.ctor'); }
      if (typeof obj.initialLiquidMMB !== 'number') { throw new Error('Invalid obj.initialLiquidMMB param supplied to Attributes.ctor'); }
      if (typeof obj.oiipMMB !== 'number') { throw new Error('Invalid obj.oiipMMB param supplied to Attributes.ctor'); }
      if (typeof obj.sanctionedInitialLiquidMMB !== 'number') { throw new Error('Invalid obj.sanctionedInitialLiquidMMB param supplied to Attributes.ctor'); }
      if (typeof obj.remainingLiquidMMB !== 'number') { throw new Error('Invalid obj.remainingLiquidMMB param supplied to Attributes.ctor'); }
      if (obj.liquidPercentRemaining !== null && typeof obj.liquidPercentRemaining !== 'number') { throw new Error('Invalid obj.liquidPercentRemaining param supplied to Attributes.ctor'); }
      if (typeof obj.liquidToFuel !== 'boolean') { throw new Error('Invalid obj.liquidToFuel param supplied to Attributes.ctor'); }
      if (typeof obj.initialGasBCF !== 'number') { throw new Error('Invalid obj.initialGasBCF param supplied to Attributes.ctor'); }
      if (typeof obj.giipBCF !== 'number') { throw new Error('Invalid obj.giipBCF param supplied to Attributes.ctor'); }
      if (typeof obj.sanctionedInitialGasBCF !== 'number') { throw new Error('Invalid obj.sanctionedInitialGasBCF param supplied to Attributes.ctor'); }
      if (typeof obj.remainingGasBCF !== 'number') { throw new Error('Invalid obj.remainingGasBCF param supplied to Attributes.ctor'); }
      if (obj.gasPercentRemaining !== null && typeof obj.gasPercentRemaining !== 'number') { throw new Error('Invalid obj.gasPercentRemaining param supplied to Attributes.ctor'); }
      if (typeof obj.gasToFuel !== 'boolean') { throw new Error('Invalid obj.gasToFuel param supplied to Attributes.ctor'); }
      if (typeof obj.expectedSanctionYear !== 'number') { throw new Error('Invalid obj.expectedSanctionYear param supplied to Attributes.ctor'); }
      if (obj.fieldApprovalDate !== null && typeof obj.fieldApprovalDate !== 'string') { throw new Error('Invalid obj.fieldApprovalDate param supplied to Attributes.ctor'); }
      if (typeof obj.firstProductionYear !== 'number') { throw new Error('Invalid obj.firstProductionYear param supplied to Attributes.ctor'); }
      if (obj.productionStartDate !== null && typeof obj.productionStartDate !== 'string') { throw new Error('Invalid obj.productionStartDate param supplied to Attributes.ctor'); }
      if (obj.productionCessationDate !== null && typeof obj.productionCessationDate !== 'string') { throw new Error('Invalid obj.productionCessationDate param supplied to Attributes.ctor'); }
      if (obj.decommissionYear !== null && typeof obj.decommissionYear !== 'number') { throw new Error('Invalid obj.decommissionYear param supplied to Attributes.ctor'); }
      if (typeof obj.reservoirAgeId !== 'number') { throw new Error('Invalid obj.reservoirAgeId param supplied to Attributes.ctor'); }
      if (typeof obj.trapTypeId !== 'number') { throw new Error('Invalid obj.trapTypeId param supplied to Attributes.ctor'); }
      if (typeof obj.reservoirDepthFt !== 'number') { throw new Error('Invalid obj.reservoirDepthFt param supplied to Attributes.ctor'); }
      if (typeof obj.gasOilContactFTTVDSS !== 'number') { throw new Error('Invalid obj.gasOilContactFTTVDSS param supplied to Attributes.ctor'); }
      if (typeof obj.gasWaterContactFTTVDSS !== 'number') { throw new Error('Invalid obj.gasWaterContactFTTVDSS param supplied to Attributes.ctor'); }
      if (typeof obj.oilWaterContactFTTVDSS !== 'number') { throw new Error('Invalid obj.oilWaterContactFTTVDSS param supplied to Attributes.ctor'); }
      if (obj.contactComment !== null && typeof obj.contactComment !== 'string') { throw new Error('Invalid obj.contactComment param supplied to Attributes.ctor'); }
      if (typeof obj.gasColumnFT !== 'number') { throw new Error('Invalid obj.gasColumnFT param supplied to Attributes.ctor'); }
      if (typeof obj.oilColumnFT !== 'number') { throw new Error('Invalid obj.oilColumnFT param supplied to Attributes.ctor'); }
      if (typeof obj.grossReservoirIntervalAvgFT !== 'number') { throw new Error('Invalid obj.grossReservoirIntervalAvgFT param supplied to Attributes.ctor'); }
      if (typeof obj.netToGrossRatioAvgFT !== 'number') { throw new Error('Invalid obj.netToGrossRatioAvgFT param supplied to Attributes.ctor'); }
      if (typeof obj.porosityAveragePercent !== 'number') { throw new Error('Invalid obj.porosityAveragePercent param supplied to Attributes.ctor'); }
      if (typeof obj.permeabilityAverageMillidarcies !== 'number') { throw new Error('Invalid obj.permeabilityAverageMillidarcies param supplied to Attributes.ctor'); }
      if (typeof obj.hydrocarbonSaturationPercent !== 'number') { throw new Error('Invalid obj.hydrocarbonSaturationPercent param supplied to Attributes.ctor'); }
      if (typeof obj.oilDensityDegApi !== 'number') { throw new Error('Invalid obj.oilDensityDegApi param supplied to Attributes.ctor'); }
      if (typeof obj.gasGravityGramsPerCC !== 'number') { throw new Error('Invalid obj.gasGravityGramsPerCC param supplied to Attributes.ctor'); }
      if (typeof obj.viscosityCentipoise !== 'number') { throw new Error('Invalid obj.viscosityCentipoise param supplied to Attributes.ctor'); }
      if (typeof obj.viscosityTemperatureFahrenheit !== 'number') { throw new Error('Invalid obj.viscosityTemperatureFahrenheit param supplied to Attributes.ctor'); }
      if (typeof obj.gasToOilRatioSCFPerBBL !== 'number') { throw new Error('Invalid obj.gasToOilRatioSCFPerBBL param supplied to Attributes.ctor'); }
      if (typeof obj.condensateToGasToRatioSTBPerMMSCF !== 'number') { throw new Error('Invalid obj.condensateToGasToRatioSTBPerMMSCF param supplied to Attributes.ctor'); }
      if (typeof obj.initialReservoirPressurePSI !== 'number') { throw new Error('Invalid obj.initialReservoirPressurePSI param supplied to Attributes.ctor'); }
      if (typeof obj.initialReservoirPressureDepthFtTVDSS !== 'number') { throw new Error('Invalid obj.initialReservoirPressureDepthFtTVDSS param supplied to Attributes.ctor'); }
      if (typeof obj.reservoirTemperatureFahrenheit !== 'number') { throw new Error('Invalid obj.reservoirTemperatureFahrenheit param supplied to Attributes.ctor'); }
      if (typeof obj.initialReservoirTemparatureDepthFtTVDSS !== 'number') { throw new Error('Invalid obj.initialReservoirTemparatureDepthFtTVDSS param supplied to Attributes.ctor'); }
      if (typeof obj.sulphurPercent !== 'number') { throw new Error('Invalid obj.sulphurPercent param supplied to Attributes.ctor'); }
      if (typeof obj.liquidPriceDiscountPremiumPercent !== 'number') { throw new Error('Invalid obj.liquidPriceDiscountPremiumPercent param supplied to Attributes.ctor'); }
      if (typeof obj.gasPriceDiscountPremiumPercent !== 'number') { throw new Error('Invalid obj.gasPriceDiscountPremiumPercent param supplied to Attributes.ctor'); }
      if (typeof obj.isPRT !== 'boolean') { throw new Error('Invalid obj.isPRT param supplied to Attributes.ctor'); }
      if (typeof obj.totalAbexUSDMM !== 'number') { throw new Error('Invalid obj.totalAbexUSDMM param supplied to Attributes.ctor'); }
      if (typeof obj.carryForward2015Allowance !== 'number') { throw new Error('Invalid obj.carryForward2015Allowance param supplied to Attributes.ctor'); }
      if (typeof obj.mapVintage !== 'number') { throw new Error('Invalid obj.mapVintage param supplied to Attributes.ctor'); }
      if (obj.sourceComment !== null && typeof obj.sourceComment !== 'string') { throw new Error('Invalid obj.sourceComment param supplied to Attributes.ctor'); }
      if (obj.importName !== null && typeof obj.importName !== 'string') { throw new Error('Invalid obj.importName param supplied to Attributes.ctor'); }
      if (obj.dateAmended !== null && typeof obj.dateAmended !== 'string') { throw new Error('Invalid obj.dateAmended param supplied to Attributes.ctor'); }
      if (typeof obj.countryId !== 'number') { throw new Error('Invalid obj.countryId param supplied to Attributes.ctor'); }
      if (typeof obj.statusParentId !== 'number') { throw new Error('Invalid obj.statusParentId param supplied to Attributes.ctor'); }
      if (typeof obj.canPromote !== 'boolean') { throw new Error('Invalid obj.canPromote param supplied to Attributes.ctor'); }
      if (typeof obj.canDemote !== 'boolean') { throw new Error('Invalid obj.canDemote param supplied to Attributes.ctor'); }
      //if (obj.officialUID !== null && typeof obj.officialUID !== 'string') { throw new Error('Invalid obj.officialUID param supplied to Attributes.ctor'); }

      // admin
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to Attributes.ctor'); }
      if (typeof obj.originalStatus !== 'number') { throw new Error('Invalid obj.originalStatus param supplied to Attributes.ctor'); }
    }

    this.id = obj === null || obj.id === null ? null : obj.id;
    this.displayName = obj === null ? '' : obj.displayName;
    this.resourceName = obj === null || obj.resourceName === null ? null : obj.resourceName;
    this.newResourceName = obj === null || obj.newResourceName === null ? null : obj.newResourceName;
    this.originalResourceName = obj === null || obj.originalResourceName === null ? null : obj.originalResourceName;
    this.blockId = obj === null ? 0 : obj.blockId;
    this.blockNo = obj === null ? '' : obj.blockNo;
    this.statusId = obj === null ? 0 : obj.statusId;
    this.typeId = obj === null ? 0 : obj.typeId;
    this.conditionId = obj === null ? 0 : obj.conditionId;
    this.productionSchemeId = obj === null ? 0 : obj.productionSchemeId;
    this.productivityGroupId = obj === null ? 0 : obj.productivityGroupId;
    this.developmentConceptId = obj === null ? 0 : obj.developmentConceptId;
    this.classificationId = obj === null ? 0 : obj.classificationId;
    this.waterDepthFt = obj === null ? 0 : obj.waterDepthFt;
    this.waterDepthM = obj === null || obj.waterDepthFt === null || obj.waterDepthFt === 0 ? null : unitConversions.Convert(unitConversions.feetToMeters, obj.waterDepthFt);
    this.activityAudited = obj === null ? false : obj.activityAudited;

    this.initialCombinedMmboe = obj === null ? 0 : obj.initialCombinedMmboe;
    this.initialCombinedM3 = obj === null || obj.initialCombinedMmboe === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.initialCombinedMmboe);

    this.remainingCombinedMmboe = obj === null ? 0 : obj.remainingCombinedMmboe;
    this.remainingCombinedM3 = obj === null || obj.remainingCombinedMmboe === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.remainingCombinedMmboe);

    this.hasWGEShape = obj === null ? false : obj.hasWGEShape;
    this.stratLevelId = obj === null || obj.stratLevelId === null ? null : obj.stratLevelId;

    this.initialLiquidMMB = obj === null ? 0 : obj.initialLiquidMMB;
    this.initialLiquidM3 = obj === null || obj.initialLiquidMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.initialLiquidMMB);

    this.oiipMMB = obj === null ? 0 : obj.oiipMMB;
    this.oiipM3 = obj === null || obj.oiipMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.oiipMMB);

    this.sanctionedInitialLiquidMMB = obj === null ? 0 : obj.sanctionedInitialLiquidMMB;
    this.sanctionedInitialLiquidM3 = obj === null || obj.sanctionedInitialLiquidMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.sanctionedInitialLiquidMMB);

    this.remainingLiquidMMB = obj === null ? 0 : obj.remainingLiquidMMB;
    this.remainingLiquidM3 = obj === null || obj.remainingLiquidMMB === 0 ? 0 : unitConversions.Convert(unitConversions.boeToCubicMeter, obj.remainingLiquidMMB);

    this.liquidPercentRemaining = obj === null || obj.liquidPercentRemaining === null ? null : obj.liquidPercentRemaining;
    this.liquidToFuel = obj === null ? false : obj.liquidToFuel;

    this.initialGasBCF = obj === null ? 0 : obj.initialGasBCF;
    this.initialGasM3 = obj === null || obj.initialGasBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.initialGasBCF);

    this.giipBCF = obj === null ? 0 : obj.giipBCF;
    this.giipM3 = obj === null || obj.giipBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.giipBCF);

    this.sanctionedInitialGasBCF = obj === null ? 0 : obj.sanctionedInitialGasBCF;
    this.sanctionedInitialGasM3 = obj === null || obj.sanctionedInitialGasBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.sanctionedInitialGasBCF);

    this.remainingGasBCF = obj === null ? 0 : obj.remainingGasBCF;
    this.remainingGasM3 = obj === null || obj.remainingGasBCF === 0 ? 0 : unitConversions.Convert(unitConversions.cubicFeetToCubicMeter, obj.remainingGasBCF);

    this.gasPercentRemaining = obj === null || obj.gasPercentRemaining === null ? null : obj.gasPercentRemaining;
    this.gasToFuel = obj === null ? false : obj.gasToFuel;
    this.expectedSanctionYear = obj === null ? 0 : obj.expectedSanctionYear;
    this.firstProductionYear = obj === null ? 0 : obj.firstProductionYear;
    this.decommissionYear = obj === null || obj.decommissionYear === null ? null : obj.decommissionYear;
    this.reservoirAgeId = obj === null ? 0 : obj.reservoirAgeId;
    this.trapTypeId = obj === null ? 0 : obj.trapTypeId;

    this.reservoirDepthFt = obj === null ? 0 : obj.reservoirDepthFt;
    this.reservoirDepthM = obj === null || obj.reservoirDepthFt === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.reservoirDepthFt);

    this.gasOilContactFTTVDSS = obj === null ? 0 : obj.gasOilContactFTTVDSS;
    this.gasOilContactMTVDSS = obj === null || obj.gasOilContactFTTVDSS === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.gasOilContactFTTVDSS);

    this.gasWaterContactFTTVDSS = obj === null ? 0 : obj.gasWaterContactFTTVDSS;
    this.gasWaterContactMTVDSS = obj === null || obj.gasWaterContactFTTVDSS === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.gasWaterContactFTTVDSS);

    this.oilWaterContactFTTVDSS = obj === null ? 0 : obj.oilWaterContactFTTVDSS;
    this.oilWaterContactMTVDSS = obj === null || obj.oilWaterContactFTTVDSS === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.oilWaterContactFTTVDSS);

    this.contactComment = obj === null || obj.contactComment === null ? null : obj.contactComment;

    this.gasColumnFT = obj === null ? 0 : obj.gasColumnFT;
    this.gasColumnM = obj === null || obj.gasColumnFT === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.gasColumnFT);

    this.oilColumnFT = obj === null ? 0 : obj.oilColumnFT;
    this.oilColumnM = obj === null || obj.oilColumnFT === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.oilColumnFT);

    this.grossReservoirIntervalAvgFT = obj === null ? 0 : obj.grossReservoirIntervalAvgFT;
    this.grossReservoirIntervalAvgM = obj === null || obj.grossReservoirIntervalAvgFT === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.grossReservoirIntervalAvgFT);

    this.netToGrossRatioAvgFT = obj === null ? 0 : obj.netToGrossRatioAvgFT;
    this.porosityAveragePercent = obj === null ? 0 : obj.porosityAveragePercent;
    this.permeabilityAverageMillidarcies = obj === null ? 0 : obj.permeabilityAverageMillidarcies;
    this.hydrocarbonSaturationPercent = obj === null ? 0 : obj.hydrocarbonSaturationPercent;
    this.oilDensityDegApi = obj === null ? 0 : obj.oilDensityDegApi;
    this.gasGravityGramsPerCC = obj === null ? 0 : obj.gasGravityGramsPerCC;
    this.viscosityCentipoise = obj === null ? 0 : obj.viscosityCentipoise;

    this.viscosityTemperatureFahrenheit = obj === null ? 0 : obj.viscosityTemperatureFahrenheit;
    this.viscosityTemperatureCelsius = obj === null || obj.viscosityTemperatureFahrenheit === 0 ? 0 : unitConversions.Convert(unitConversions.fahrenheitToCelsius, obj.viscosityTemperatureFahrenheit);

    this.gasToOilRatioSCFPerBBL = obj === null ? 0 : obj.gasToOilRatioSCFPerBBL;
    this.condensateToGasToRatioSTBPerMMSCF = obj === null ? 0 : obj.condensateToGasToRatioSTBPerMMSCF;

    this.initialReservoirPressurePSI = obj === null ? 0 : obj.initialReservoirPressurePSI;
    this.initialReservoirPressureKPA = obj === null || obj.initialReservoirPressurePSI === 0 ? 0 : unitConversions.Convert(unitConversions.psiToKilopascal, obj.initialReservoirPressurePSI);

    this.initialReservoirPressureDepthFtTVDSS = obj === null ? 0 : obj.initialReservoirPressureDepthFtTVDSS;
    this.initialReservoirPressureDepthMTVDSS = obj === null || obj.initialReservoirPressureDepthFtTVDSS === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.initialReservoirPressureDepthFtTVDSS);

    this.initialReservoirTemparatureDepthFtTVDSS = obj === null ? 0 : obj.initialReservoirTemparatureDepthFtTVDSS;
    this.initialReservoirTemparatureDepthMTVDSS = obj === null || obj.initialReservoirTemparatureDepthFtTVDSS === 0 ? 0 : unitConversions.Convert(unitConversions.feetToMeters, obj.initialReservoirTemparatureDepthFtTVDSS);

    this.reservoirTemperatureFahrenheit = obj === null ? 0 : obj.reservoirTemperatureFahrenheit;
    this.reservoirTemperatureCelsius = obj === null || obj.reservoirTemperatureFahrenheit === 0 ? 0 : unitConversions.Convert(unitConversions.fahrenheitToCelsius, obj.reservoirTemperatureFahrenheit);

    this.sulphurPercent = obj === null ? 0 : obj.sulphurPercent;
    this.liquidPriceDiscountPremiumPercent = obj === null ? 0 : obj.liquidPriceDiscountPremiumPercent;
    this.gasPriceDiscountPremiumPercent = obj === null ? 0 : obj.gasPriceDiscountPremiumPercent;
    this.isPRT = obj === null ? false : obj.isPRT;
    this.totalAbexUSDMM = obj === null ? 0 : obj.totalAbexUSDMM;
    this.carryForward2015Allowance = obj === null ? 0 : obj.carryForward2015Allowance;
    this.mapVintage = obj === null ? 0 : obj.mapVintage;
    this.sourceComment = obj === null || obj.sourceComment === null ? null : obj.sourceComment;
    this.importName = obj === null || obj.importName === null ? null : obj.importName;
    this.countryId = obj === null ? 0 : obj.countryId;
    this.statusParentId = obj === null ? 0 : obj.statusParentId;
    this.canPromote = obj === null ? false : obj.canPromote;
    this.canDemote = obj === null ? false : obj.canDemote;

    //dates
    this.fieldApprovalDate = obj === null ? null : getDate(obj.fieldApprovalDate);
    this.productionStartDate = obj === null ? null : getDate(obj.productionStartDate);
    this.productionCessationDate = obj === null ? null : getDate(obj.productionCessationDate);

    this.dateAmended = obj === null ? null : obj.dateAmended;

    // admin
    //this.officialUID = obj === null ? null : obj.officialUID;
    this.status = obj === null ? 0 : obj.status;
    this.originalStatus = obj === null ? 0 : obj.originalStatus;
    this.canEditOwnership = obj === null ? false : obj.canEditOwnership;
    //spatial
    this.shapes = undefined;
    if (obj !== undefined) {
      if (obj.shapes !== undefined && obj.shapes !== null) {
        this.shapes = new PolygonsModel(obj.shapes.map(obj => new PolygonModel(obj)), { borderColor: MapShapeBorder, fillColor: 'transparent' });
      }
      else if (obj.points !== undefined && obj.points !== null && obj.points.length > 0) {
        let item = obj.points[0];
        this.shapes = new CircleModel(item.y, item.x, { borderColor: MapShapeBorder, fillColor: 'transparent', radius: 1000 });
      }
    }
    this.points = obj === undefined || obj.points === undefined || obj.points === null ? undefined : obj.points.map(item => new CircleModel(item.y, item.x, { borderColor: MapShapeBorder, fillColor: 'transparent', radius: 400 }));

    // collections
    this.resourceAlias = (obj === null || obj.resourceAlias === null) ? [new ResourceAlias(null)] : obj.resourceAlias.map(item => new ResourceAlias(item));
    this.resourceBlock = (obj === null || obj.resourceBlock === null) ? [new ResourceBlock(null)] : obj.resourceBlock.map(item => new ResourceBlock(item));
    this.resourceComments = (obj === null || obj.resourceComments === null) ? [new GMatrixGenericComment(null)] : obj.resourceComments.map(item => new GMatrixGenericComment(item));
    this.productionComments = (obj === null || obj.productionComments === null) ? [new GMatrixGenericComment(null)] : obj.productionComments.map(item => new GMatrixGenericComment(item));
    this.resourceDescriptiveText = (obj === null || obj.resourceDescriptiveText === null) ? [new ResourceText(null)] : obj.resourceDescriptiveText.map(item => new ResourceText(item));
    this.locations = (obj === null || obj.locations === null) ? [new GenericLocation(null)] : obj.locations.map(item => new GenericLocation(item));
    this.ownership = (obj === null || obj.ownership === null) ? [new Ownership(null)] : obj.ownership.map(item => new Ownership(item));
    this.resourceProcessing = (obj === null || obj.resourceProcessing === null) ? [new ResourceProcessing(null)] : obj.resourceProcessing.map(item => new ResourceProcessing(item));
    this.resourceWell = (obj === null || obj.resourceWell === null) ? [new ResourceWell(null)] : obj.resourceWell.map(item => new ResourceWell(item));
    this.resourceAnnuals = (obj === null || obj.resourceAnnuals === null) ? [new ResourceAnnual(null)] : obj.resourceAnnuals.map(item => new ResourceAnnual(item));
    this.officialUID = (obj === null || obj.officialUID === null) ? [new OfficialUID(null)] : obj.officialUID.map(item => new OfficialUID(item));
    this.dpfFields = (obj === null || obj.dpfFields === null) ? null : obj.dpfFields.map(item => new ResourceWestwoodDataField(item));
  }
}

export class ResourceWestwoodDataField {
  constructor(obj) {
    if (obj !== null && typeof obj !== 'object') { throw new Error('Invalid obj param supplied to ResourceWestwoodDataField.ctor'); }
    if (obj !== null) {
      if (typeof obj.id !== 'number') { throw new Error('Invalid obj.id param supplied to ResourceWestwoodDataField.ctor'); }
      if (typeof obj.resourceId !== 'number') { throw new Error('Invalid obj.resourceId param supplied to ResourceWestwoodDataField.ctor'); }
      if (obj.fieldId !== undefined && obj.fieldId !== null && typeof obj.fieldId !== 'string') { throw new Error('Invalid obj.fieldId param supplied to ResourceWestwoodDataField.ctor'); }

      if (typeof obj.allowAdd !== 'boolean') { throw new Error('Invalid obj.allowAdd param supplied to ResourceWestwoodDataField.ctor'); }
      if (typeof obj.allowRemove !== 'boolean') { throw new Error('Invalid obj.allowRemove param supplied to ResourceWestwoodDataField.ctor'); }
      if (typeof obj.status !== 'number') { throw new Error('Invalid obj.status param supplied to ResourceWestwoodDataField.ctor'); }
      if (typeof obj.selected !== 'boolean') { throw new Error('Invalid obj.selected param supplied to ResourceWestwoodDataField.ctor'); }
    }

    this.id = obj === null ? 0 : obj.id;
    this.resourceId = obj === null ? 0 : obj.resourceId;
    this.fieldId = obj === null ? null : obj.fieldId;
    this.field = obj === null ? null : new WestwoodDataDPFField(obj.field);

    this.allowAdd = obj === null ? true : obj.allowAdd;
    this.allowRemove = obj === null ? true : obj.allowRemove;
    this.status = obj === null ? '' : obj.status;
    this.selected = obj === null ? false : obj.selected;
  }

  getNew(resourceId) {
    this.resourceId = resourceId;
    this.selected = true;
    this.status = RECORD_STATUS.added;
    this.fieldId = null;
    this.field = null;
  }
}


export class AssociatedDataContents {
  canHaveAlias = false;
  canHaveBlock = false;
  canHaveComments = false;
  canHaveDescriptiveText = false;
  canHaveLocation = false;
  canHaveOwnership = false;
  canHaveProcessing = false;
  canHaveWell = false;
  canHaveWestwoodDataField = false;
  canHaveWestwoodDataPlatform = false;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }
}

export class Result {
  constructor(obj) {
    obj && Object.assign(this, obj);
    this.attributes = obj === null || obj === undefined ? new Attributes(null) : new Attributes(obj.attributes);
    this.associatedDataContents = obj === null || obj === undefined ? new AssociatedDataContents(null) : new AssociatedDataContents(obj.associatedDataContents);
    this.issues = obj === null || obj === undefined ? [new Issue()] : obj.issues.map(item => new Issue(item));
    this.editLock = obj === null || obj === undefined ? new EditLock() : new EditLock(obj.editLock);
  }
}

export { ResourceText, ResourceAnnual, Issue, EditLock, unitConversions };
