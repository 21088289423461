import React from 'react';
import PropTypes from 'prop-types';

import { LoadingGlobal, MessageBoxGlobal } from '../../../../common/globals/globals';
import SplitterPanelComponent from '../../../../shared/components/splitter-panel/splitter-panel-component';
import TabPanelComponent, { Tab } from '../../../../shared/components/tab-panel/tab-panel-component';
import CommentsComponent from '../../../../common/components/comments/comments-component';
import { GMatrixGenericComment } from '../../../../common/models/generic-comment';
import LocationComponent from '../../../../common/components/location/location-component';
import GenericLocation from '../../../../common/models/generic-location';
import Ownership from '../../../../common/models/ownership';
import OwnershipComponent from '../../../../common/components/ownership/ownership-component';
import { ResourceWell } from '../../../../common/models/resource-well';
import ResourceWellComponent from '../../../../common/components/resource-well/resource-well-component';
import { LookupResult } from '../../../../common/models/lookup-result';
import { RELATED_ITEM_TYPE } from '../../../../common/models/related-item-type';
import { WestwoodDataWell } from '../../../../common/models/westwood-data-well';
import WestwoodDataWellComponent from '../../../../common/components/westwood-data-well/westwood-data-well-component';
import { RESOURCE_PARENT_IDS } from '../../../../common/globals/enums'
const BottomPanelComponent = (props) => {
  const {
    editing,
    loadingGlobal,
    messageBoxGlobal,
    countryId,
    isPlanned,
    comments,
    commentActions,
    locations,
    locationActions,
    ownership,
    ownershipActions,
    ownershipLookupURL,
    resourceWell,
    resourceWellActions,
    lookupsFiltered,
    entityId,
    westwoodDataWell
  } = props;

  let fields = resourceWell.filter(x => x.resourceStatusParentId === RESOURCE_PARENT_IDS.FIELD);
  let fieldLookups = lookupsFiltered.Fields;

  let discoveries = resourceWell.filter(x => x.resourceStatusParentId === RESOURCE_PARENT_IDS.DISCOVERY);
  let discoveryLookups = lookupsFiltered.Discoveries;

  let prospects = resourceWell.filter(x => x.resourceStatusParentId === RESOURCE_PARENT_IDS.PROSPECT);
  let prospectLookups = lookupsFiltered.Prospects;

  let allowLocationAdd = locations !== null && locations.length >= 2 ? false : true;
  let allowLocationRemove = locations === null || locations.length === 0 ? false : true;

  let selectedTab = 0;
  const leftTab = [];
  const rightTab = [];

  if (westwoodDataWell !== null && westwoodDataWell.id !== null) {
    leftTab.push(new Tab('Westwood Well', <WestwoodDataWellComponent westwoodDataWell={westwoodDataWell} />));
  }

  if (!isPlanned) {
    leftTab.push(new Tab('Ownership', <OwnershipComponent editing={editing} messageBoxGlobal={messageBoxGlobal} loadingGlobal={loadingGlobal} ownership={ownership} ownershipActions={ownershipActions} lookupURL={ownershipLookupURL} isWell id={entityId} />));
  }

  leftTab.push(new Tab('Fields',
    <ResourceWellComponent
      editing={editing}
      label={'Fields'}
      resourceStatusParentId={RESOURCE_PARENT_IDS.FIELD}
      messageBoxGlobal={messageBoxGlobal}
      resources={fieldLookups}
      resourceWell={fields}
      resourceWellActions={resourceWellActions(RELATED_ITEM_TYPE.FIELD)}
      countryId={countryId}
      fromWell
      resourceId={-1}
      wellId={entityId}
      //relatedItemTypeId={RELATED_ITEM_TYPE.FIELD}
    />));

  leftTab.push(new Tab('Discoveries',
    <ResourceWellComponent
      editing={editing}
      label={'Discoveries'}
      resourceStatusParentId={RESOURCE_PARENT_IDS.DISCOVERY}
      messageBoxGlobal={messageBoxGlobal}
      resources={discoveryLookups}
      resourceWell={discoveries}
      resourceWellActions={resourceWellActions(RELATED_ITEM_TYPE.DISCOVERY)}
      countryId={countryId}
      fromWell
      resourceId={-1}
      wellId={entityId}
      //relatedItemTypeId={RELATED_ITEM_TYPE.DISCOVERY}
    />));

  leftTab.push(new Tab('Prospects',
    <ResourceWellComponent
      editing={editing}
      label={'Prospects'}
      resourceStatusParentId={RESOURCE_PARENT_IDS.PROSPECT}
      messageBoxGlobal={messageBoxGlobal}
      resources={prospectLookups}
      resourceWell={prospects}
      resourceWellActions={resourceWellActions(RELATED_ITEM_TYPE.PROSPECT)}
      countryId={countryId}
      fromWell
      resourceId={-1}
      wellId={entityId}
      //relatedItemTypeId={RELATED_ITEM_TYPE.PROSPECT}
    />));

  rightTab.push(new Tab('Location',
    <LocationComponent
      editing={editing}
      loadingGlobal={loadingGlobal}
      messageBoxGlobal={messageBoxGlobal}
      locations={locations}
      locationActions={locationActions}
      showIntendedCountry={false}
      showStratSplit={false}
      showBottomHole={true}
      allowAdd={allowLocationAdd}
      allowRemove={allowLocationRemove}
    />));

  rightTab.push(new Tab('Comments', <CommentsComponent editing={editing} messageBoxGlobal={messageBoxGlobal} comments={comments} commentActions={commentActions} />));
  
  return (
    <SplitterPanelComponent vertical percentage secondarySize={50}>
      <TabPanelComponent align="top" toolbarItems={leftTab} selectedItemIndex={selectedTab} />
      <TabPanelComponent align="top" toolbarItems={rightTab} selectedItemIndex={selectedTab} />
    </SplitterPanelComponent>
  );
};

BottomPanelComponent.propTypes = {
  editing: PropTypes.bool.isRequired,
  loadingGlobal: PropTypes.instanceOf(LoadingGlobal).isRequired,
  messageBoxGlobal: PropTypes.instanceOf(MessageBoxGlobal).isRequired,
  isPlanned: PropTypes.bool.isRequired,
  comments: PropTypes.arrayOf(PropTypes.instanceOf(GMatrixGenericComment)).isRequired,
  commentActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  locations: PropTypes.arrayOf(PropTypes.instanceOf(GenericLocation)).isRequired,
  locationActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  ownership: PropTypes.arrayOf(PropTypes.instanceOf(Ownership)),
  ownershipActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  ownershipLookupURL: PropTypes.string.isRequired,
  resourceWell: PropTypes.arrayOf(PropTypes.instanceOf(ResourceWell)).isRequired,
  //resourceWellActions: PropTypes.shape({ onAdd: PropTypes.func.isRequired, onDelete: PropTypes.func.isRequired, onEdit: PropTypes.func.isRequired }).isRequired,
  resourceWellActions: PropTypes.func.isRequired,
  lookupsFiltered: PropTypes.instanceOf(LookupResult).isRequired,
  westwoodDataWell: PropTypes.instanceOf(WestwoodDataWell),
};

export default BottomPanelComponent;
